function toggleMenu() {
    menu_button = jQuery('#menu-button');
    menu = jQuery('#menu');

    if (menu_button.hasClass('is-active')) {
        menu_button.removeClass('is-active');
        menu.removeClass('is-active');
    } else {
        menu_button.addClass('is-active');
        menu.addClass('is-active');
    }
}

var scroll = 0;
var primary_navbar = jQuery('#primary-navbar');
var menu_button = jQuery('#menu-button');
var logo_light = jQuery('#logo-light');
var logo_dark = jQuery('#logo-dark');
var start_dark = primary_navbar.hasClass('light');

jQuery(window).scroll(function() {
    scroll = jQuery(window).scrollTop();

    if (scroll > 100) {
        primary_navbar.addClass('bg-white shadow');
        if (start_dark) {
            menu_button.removeClass('light');
        }
        logo_light.addClass('d-none');
        logo_dark.removeClass('d-none');
    } else {
        primary_navbar.removeClass('bg-white shadow');
        if (start_dark) {
            menu_button.addClass('light');
        }
        logo_light.removeClass('d-none');
        logo_dark.addClass('d-none');
    }
});